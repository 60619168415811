<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>
    <!-- <div class="banner-container">
      <van-swipe ref="banner" :autoplay="5000" class="fill">
        <van-swipe-item v-for="(item, idx) in banners" :key="idx">
          <div class="fill" @click="urlLink(item.url)">
            <img :src="item.bannerPicture" alt="" style="width: 100%; height: auto;">
          </div>
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="content-container">
      <h1 class="text-center">{{detail.newsTitle || detail.articleTitle}}</h1>
      <div class="brand-box flex justify-center align-center text-center">
        <!-- <img :src="detail.logo" alt="" class="logo">
        <div class="position">{{detail.positionNumber}}</div>
        <div class="brand">{{detail.brandName}}</div> -->
      </div>
      <div class="news-detail-content" v-html="detail.content"></div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getNewsDetail } from '@/api/index.js'

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  data() {
    return {
      id: '',
      current: '',
      detail: {},
    }
  },
  created() {
    let {type = '', id} = this.$route.query;
    this.type = type;
    this.id = id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      getNewsDetail(this.id).then(res => {
        this.detail = res.resultData;
        document.title = this.detail.newsTitle
        this.detail.content = res.resultData.content;
      })
    }
  },
}
</script>

<style lang="less">
  .news-detail-content {
    img {
      display:block;
      margin:0 auto;
      margin: 8px auto!important;
      border-radius: 1px;
    }
  }
</style>
<style scoped lang="less">
  
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 360px;
    margin: 20px auto 50px;
    
    h1 {
      margin-bottom: 10px;
      font-size: 16px;
    }
    
    .brand-box {
      margin-bottom: 5px;
      border-bottom: 1Px solid #ededed;
      padding-bottom: 4px;
      
      .logo {
        display: block;
        width: 50px;
        height: auto;
        margin-right: 20px;
      }
      
      .position,
      .brand {
        font-size: 14px;
        margin-right: 20px;
      }
    }
    
    .news-detail-content {
      width: 340px;
      margin: 20px auto;
      font-size: 14px;
      // text-indent: 2em;
      white-space: pre-wrap;
      word-wrap: break-word;
      // white-space: nowrap;
    }
  }
}

@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 10px auto 20px;
    
    h1 {
      margin-bottom: 4px;
      font-size: 5.6px;
    }
    
    .brand-box {
      margin-bottom: 5px;
      border-bottom: 1Px solid #ededed;
      padding-bottom: 4px;
      
      .logo {
        display: block;
        width: 10px;
        height: auto;
        margin-right: 5px;
      }
      
      .position,
      .brand {
        font-size: 4px;
        margin-right: 5px;
      }
    }
    
    .news-detail-content {
      width: 70%;
      margin: 0 auto;
      font-size: 3.2px;
      // text-indent: 2em;
      white-space: pre-wrap;
      word-wrap: break-word;
      // white-space: nowrap;
    }
  }
}

  
</style>